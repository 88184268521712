// components/Home.js
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import "./container.css";
import Grid from "@mui/material/Grid";
import NavBar from "../../layout/navbar/Navbar";
import { Typography } from "@mui/material";
import Maps from "../../components/maps/Maps";
import { Button, TextField } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import controlRoomMain from "../../assets/woodstockie/pizza/ec9dba35-e1d6-44fb-9a7d-0936107f77bf.webp";
import controlRoom1 from "../../assets/woodstockie/control_room/IMG_4195[1].webp";
import controlRoom2 from "../../assets/woodstockie/control_room/IMG_4806.webp";
import controlRoom3 from "../../assets/woodstockie/control_room/IMG_4807.webp"
import controlRoom4 from "../../assets/woodstockie/control_room/IMG_4808.webp";
import controlRoom5 from "../../assets/woodstockie/control_room/IMG_7752.webp";
import controlRoom6 from "../../assets/woodstockie/control_room/IMG_7753.webp";
import controlRoom7 from "../../assets/woodstockie/control_room/IMG_7755.webp";
import controlRoom8 from "../../assets/woodstockie/control_room/IMG_7786.webp";
import controlRoom9 from "../../assets/woodstockie/control_room/IMG_7787.webp";
import Stack from "@mui/material/Stack";

import containerImage1 from "../../assets/woodstockie/containers/container.jpg";
import containerImage2 from "../../assets/woodstockie/containers/container1.jpg";
import containerImage3 from "../../assets/woodstockie/containers/container2.jpg";
import containerImage4 from "../../assets/woodstockie/containers/container3.jpg";

import serviceImage1 from "../../assets/woodstockie/home/PHOTO-2021-11-13-13-06-23.jpg";
import serviceImage2 from "../../assets/woodstockie/control_room/IMG_4808.webp";
import serviceImage3 from "../../assets/woodstockie/classroom/IMG_8857.webp";
import serviceImage4 from "../../assets/woodstockie/pizza/d8cd2100-a9dd-42c9-8b21-375050d5651e.webp";
import serviceImage5 from "../../assets/woodstockie/offices/container8.webp";
import serviceImage6 from "../../assets/woodstockie/ablutions/661ef52e-837b-42d4-b965-1bb5668b2a9e.webp";

const serviceImages = [
  controlRoom1,
  containerImage1,
  containerImage2,
  controlRoom4,
  controlRoom5,
  controlRoom6,
  containerImage4,
  controlRoom8,
  containerImage3,
  // Add more image paths as needed
];

export function WContainer() {

  const cardData = [
    {
      id: 1,
      image: serviceImage1,
      title: "Modern Homes",
      link: "/modernHomes",
      message: "Redefine contemporary living with modern homes crafted from containers."
    },
    {
      id: 2,
      image: serviceImage2,
      title: "Control Rooms",
      link: "/control_room",
      message: "Enhance operational efficiency and coordination with control rooms tailored to your construction site's needs."
    },
    {
      id: 3,
      image: serviceImage3,
      title: "Class Rooms",
      link: "/classrooms",
      message: "Create engaging and functional learning spaces."
    },
    {
      id: 4,
      image: serviceImage4,
      title: "Restaurants",
      link: "/restaurants",
      message: "Elevate the dining experience with inventive container-based restaurant"
    },
    {
      id: 5,
      image: serviceImage5,
      title: "Offices",
      link: "/offices",
      message: "Elevate productivity and collaboration with container-based office solutions tailored to your organization's needs."
    },
    {
      id: 6,
      image: serviceImage6,
      title: "Ablutions",
      link: "/ablutions",
      message: "Prioritize hygiene and convenience with container-based ablution solution."
    },

    // Add more objects as needed
  ];


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Grid container sx={{ backgroundColor: "#B5BEC366" }}>

        <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", paddingTop: "10px", backgroundColor: "#024512" }}>
        <Grid item xs={12} sx={{ margin: "2vh 0 6vh 0" }}>
            <NavBar />
          </Grid>


          <Grid container sx={{ display: "flex", justifyContent: "space-evenly", margin: { xs: 0, md: "15vh auto", lg: 0 } }}>
            <Grid item xs={12} md={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000" }}>
              <Stack direction={"column"}>
                <Typography
                  variant="h1"
                  sx={{ fontSize: { xs: "2rem", lg: "3rem" }, marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #7BC24F, #FDF000)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}
                >
                  {" "} Container {" "}
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Discover the world of possibilities with container solutions from Woodstock Projects. Transforming standard containers into dynamic spaces, we offer tailored solutions that redefine versatility and innovation.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  <ul style={{ textAlign: "left", color: "#fff", }}>
                    <li>
                      <b>Custom Conversions:</b> Our expertise lies in converting containers into a wide range of functional spaces, from kitchen units to classrooms.
                    </li>
                    <li>
                      <b>Creative Configurations</b>: Experience the freedom to design your ideal space by leveraging the modular nature of containers.
                    </li>
                    <li>
                      <b>Endless Applications:</b> Whether it's offices, accommodation, or storage facilities, our container solutions cater to diverse needs.
                    </li>
                    <li>
                      <b>Quality and Affordability:</b> Benefit from our commitment to using high-quality materials and delivering exceptional craftsmanship at competitive prices.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Embark on a journey of innovation with container solutions that redefine space and functionality. <b>Woodstock Projects</b> is your partner in turning concepts into reality.
                </Typography>

              </Stack>
            </Grid>
            <br />
            <Grid item xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={controlRoomMain} alt="" style={{ width: "90%", borderRadius: "0.2vw", height: "40vh", objectFit: "cover" }} />
            </Grid>

          </Grid>
          <br />
        </Grid>

        <Grid
          container
          className="servicesContainer"
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "6vh",
            margin: "2vh 3vw",
            borderRadius: "0.6vw"
          }}>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>

            <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: 600, background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: "1.7rem" }}>
              {" "}
              Solutions{" "}
            </Typography>
          </Grid>


          {/* Container use cases */}
          {cardData.map(item => (
            <Grid
              item
              className="container"
              xs={9}
              sm={6}
              md={2}
              component={Link}
              to={item.link}
              sx={{ marginTop: "1.5vw", marginBottom: "1.5vw", objectFit: { xs: "cover", md: "none", lg: "cover" } }}
              key={item.id}
            >
              <div key={item.id} className="card1">
                <div className="front1">
                  <div className="grid-container">
                    <img className="card-img" src={item.image} alt="" style={{ maxWidth: "100%", height: "20vh" }} />
                    <Typography
                      component={Link}
                      to={item.link}
                      variant="body1"
                      sx={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "1.1em",
                        textDecoration: "none",
                        gridColumn: "1", // Position the text in the first column
                        gridRow: "2", // Position the text in the second row
                        justifySelf: "center", // Center the text horizontally
                        "&:hover": {
                          color: "#7BC24F"
                        }
                      }}
                    >
                      {item.title}
                    </Typography>
                  </div>
                </div>
                <div className="back1">
                  <Typography
                    component={Link}
                    to={item.link}
                    variant="body1"
                    sx={{
                      color: "#fff",
                      fontFamily: "Poppins",
                      fontSize: "1.1em",
                      textDecoration: "none",
                      gridColumn: "1", // Position the text in the first column
                      gridRow: "2", // Position the text in the second row
                      justifySelf: "center", // Center the text horizontally
                      "&:hover": {
                        color: "#7BC24F"
                      }
                    }}
                  >
                    {item.message}
                  </Typography>

                </div>
              </div>
            </Grid>
          ))}

        </Grid>

        <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
          <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: 600, background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: "1.7rem" }}>
            Gallery
          </Typography>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
            {/* ... (previous code) */}
            {/* Loop through the images array and group them in rows of three */}
            {Array.from({ length: Math.ceil(serviceImages.length / 3) }).map((_, rowIndex) => (
              <Grid key={rowIndex} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%", height: "100%" }}>
                  {/* Generate image components within the loop */}
                  {serviceImages.slice(rowIndex * 3, rowIndex * 3 + 3).map((imagePath, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ margin: "0.5vw" }}>
                      <img className="imageCon" src={imagePath} />
                    </Grid>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
