import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import awsExports from "./aws-exports";
import "./index.css";
import { Amplify } from 'aws-amplify';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Amplify.configure(awsExports);

console.log(process.env.GOOGLE_TOKEN)

Sentry.init({
  dsn: "https://3dae93a978f84ea79b82f400b17447b9@o796007.ingest.sentry.io/4504694620225536",
  integrations: [
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: `${process.env.REACT_APP_SENTRY_ENV}`,
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
