// components/Home.js
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import "./storage.css";
import Grid from "@mui/material/Grid";
import NavBar from "../../layout/navbar/Navbar";
import { Typography } from "@mui/material";
import Maps from "../../components/maps/Maps";
import { Button, TextField } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import controlRoomMain from "../../assets/woodstockie/control_room/IMG_4808.webp";
import controlRoom1 from "../../assets/woodstockie/control_room/IMG_4195[1].webp";
import controlRoom2 from "../../assets/woodstockie/control_room/IMG_4806.webp";
import controlRoom3 from "../../assets/woodstockie/control_room/IMG_4807.webp"
import controlRoom4 from "../../assets/woodstockie/control_room/IMG_4808.webp";
import controlRoom5 from "../../assets/woodstockie/control_room/IMG_7752.webp";
import controlRoom6 from "../../assets/woodstockie/control_room/IMG_7753.webp";
import controlRoom7 from "../../assets/woodstockie/control_room/IMG_7755.webp";
import controlRoom8 from "../../assets/woodstockie/control_room/IMG_7786.webp";
import controlRoom9 from "../../assets/woodstockie/control_room/IMG_7787.webp";
import Stack from "@mui/material/Stack";

const controlRoomImages = [
  controlRoom1,
  controlRoom2,
  controlRoom3,
  controlRoom4,
  controlRoom5,
  controlRoom6,
  controlRoom7,
  controlRoom8,
  controlRoom9,
  // Add more image paths as needed
];


export function Storage() {

  return (
    <>
      <Grid container sx={{ backgroundColor: "#B5BEC366" }}>

        <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", backgroundColor: "#024512" }}>
          <Grid item xs={12} sx={{ margin: "2vh 0 6vh 0" }}>
            <NavBar />
          </Grid>

          <Grid container sx={{ display: "flex", justifyContent: "space-evenly", margin: { xs: 0, md: "15vh auto", lg: 0 } }}>
            <Grid item xs={12} md={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000" }}>
              <Stack direction={"column"}>
                <Typography
                  variant="h1"
                  sx={{ fontSize: { xs: "2rem", lg: "3rem" }, marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #7BC24F, #FDF000)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}
                >
                  {" "} Storage Units {" "}
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Elevate your peace of mind with our secure storage units. Experience the assurance of knowing your belongings are safeguarded by state-of-the-art security measures, enabling you to store with utmost confidence.
                </Typography>
                <br/>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                <ul style={{ textAlign: "left", color: "#fff" }}>
                  <li>
                    <b>Cutting-Edge Security:</b> Our storage units are equipped with top-notch security features to ensure the protection of your valuable possessions.
                  </li>
                  <li>
                    <b>Versatile Solutions:</b> Whether you're storing seasonal items or long-term valuables, our storage units offer the flexibility to meet your diverse needs.
                  </li>
                  <li>
                    <b>Convenient Access:</b> Our user-friendly approach provides you with easy access to your stored items whenever you need them.
                  </li>
                </ul>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Discover the difference that secure storage units can make in your life. Experience a new level of convenience and protection with Woodstock Projects.
                </Typography>

              </Stack>
            </Grid>

            <Grid item xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
              <img src={controlRoomMain} alt="" style={{ width: "90%", borderRadius: "0.2vw", height: "80%", objectFit: "cover" }} />
            </Grid>
          </Grid>
        </Grid>


        <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
          <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: 600, background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: "1.7rem" }}>
            Gallery
          </Typography>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
            {/* ... (previous code) */}

            {/* Loop through the images array and group them in rows of three */}
            {Array.from({ length: Math.ceil(controlRoomImages.length / 3) }).map((_, rowIndex) => (
              <Grid key={rowIndex} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }}>
                  {/* Generate image components within the loop */}
                  {controlRoomImages.slice(rowIndex * 3, rowIndex * 3 + 3).map((imagePath, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ margin: "0.5vw" }}>
                      <img className="imageCon" src={imagePath} />
                    </Grid>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>

        </Grid>
      </Grid>
    </>
  );
}
