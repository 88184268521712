// components/Home.js
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import "./ablutions.css"
import Grid from "@mui/material/Grid";
import NavBar from "../../layout/navbar/Navbar";
import { Typography } from "@mui/material";
import Maps from "../../components/maps/Maps";
import { Button, TextField } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import ablutionsMain from "../../assets/woodstockie/ablutions/IMG_8531.webp";
import container1 from "../../assets/woodstockie/ablutions/IMG_1178.webp";
import container2 from "../../assets/woodstockie/ablutions/IMG_8531.webp";
import container3 from "../../assets/woodstockie/ablutions/23eace63-1fc5-4741-9f0b-585ec7152d07.webp";
import container4 from "../../assets/woodstockie/ablutions/25c80e2b-17a8-446a-b778-b7a35f019da3.webp";
import container5 from "../../assets/woodstockie/ablutions/439e0dc0-e4cf-436d-8a8a-eb377d2aea04.webp";
import container6 from "../../assets/woodstockie/ablutions/850a357d-e4d1-41ec-b179-1ef4079888df.webp";
import container7 from "../../assets/woodstockie/ablutions/31442c41-b93d-4b99-9a6a-51bf20c2722e.webp";
import container8 from "../../assets/woodstockie/ablutions/IMG_1197.webp";
import container9 from "../../assets/woodstockie/ablutions/IMG_8498[5].webp";
import Stack from "@mui/material/Stack";

const imgGroup = [
  container1,
  container2,
  container3,
  container4,
  container5,
  container6,
  container7,
  container8,
  container9,
  // Add more image paths as needed
];

export function Ablutions() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (

    <>
      <Grid container sx={{ backgroundColor: "#B5BEC366" }}>

        <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", backgroundColor: "#024512" }}>
          <Grid item xs={12} sx={{ marginBottom: "4%" }}>
            <NavBar />
          </Grid>


          <Grid container sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <Grid item xs={12} md={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000", marginTop: "0vh" }}>
              <Stack direction={"column"}>
                <Typography
                  variant="h1"
                  sx={{ fontSize: "2.5em", marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #7BC24F, #FDF000)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}
                >
                  {" "} Ablutions {" "}
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Prioritize hygiene and convenience with container-based ablution solutions from <b>Woodstock Projects.</b> Our custom-designed ablutions ensure comfort, functionality, and durability in various settings.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  <ul style={{ textAlign: "left", color: "#fff" }}>
                    <li>
                      <b>Customized Designs:</b> Create ablution facilities tailored to your specific needs, whether for events, construction sites, or public areas.
                    </li>
                    <li>
                      <b>Efficient Layouts:</b> Optimize space with thoughtfully planned layouts that accommodate multiple facilities in a compact space.
                    </li>
                    <li>
                      <b>Durable and Weather-Resistant:</b> Our ablutions are built to withstand harsh conditions while maintaining structural integrity.
                    </li>
                    <li>
                      <b>Sanitary Solutions:</b> Incorporate modern fixtures and materials to ensure a clean and hygienic environment for users.
                    </li>
                    <li>
                      <b>Versatile Applications: </b>Our ablutions are suitable for a wide range of settings, providing comfort and convenience wherever needed.
                    </li>
                    <li>
                      <b>Cost-Effective:</b> Save on construction costs without compromising on quality and functionality.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" }, paddingBottom: "3vh" }}>
                  Elevate ablution facilities with container solutions that prioritize comfort, durability, and hygiene. <b>Woodstock Projects</b> delivers practical solutions for various environments.
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0vh"
              }}>
              <img src={ablutionsMain} alt="" style={{ width: "90%", borderRadius: "0.2vw", height: "40vh", objectFit: "cover" }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid className="image-container" xs={12} sx={{ marginTop: "6vh", marginBottom: "6vh" }}>
          <Typography variant="h5" mb={4} sx={{ fontFamily: "Poppins", fontVariant: "small-caps", background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 500, fontSize: "1.7rem" }}> Gallery </Typography>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
            {/* ... (previous code) */}
            {/* Loop through the images array and group them in rows of three */}
            {Array.from({ length: Math.ceil(imgGroup.length / 3) }).map((_, rowIndex) => (
              <Grid key={rowIndex} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%", height: "100%" }}>
                  {/* Generate image components within the loop */}
                  {imgGroup.slice(rowIndex * 3, rowIndex * 3 + 3).map((imagePath, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ margin: "0.5vw" }}>
                      <img className="imageCon" src={imagePath} />
                    </Grid>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}
