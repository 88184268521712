// components/Home.js
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import "./control_room.css";
import Grid from "@mui/material/Grid";
import NavBar from "../../layout/navbar/Navbar";
import { Typography } from "@mui/material";
import Maps from "../../components/maps/Maps";
import { Button, TextField } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import controlRoomMain from "../../assets/woodstockie/control_room/IMG_4808.webp";
import controlRoom1 from "../../assets/woodstockie/control_room/IMG_4195[1].webp";
import controlRoom2 from "../../assets/woodstockie/control_room/IMG_4806.webp";
import controlRoom3 from "../../assets/woodstockie/control_room/IMG_4807.webp"
import controlRoom4 from "../../assets/woodstockie/control_room/IMG_4808.webp";
import controlRoom5 from "../../assets/woodstockie/control_room/IMG_7752.webp";
import controlRoom6 from "../../assets/woodstockie/control_room/IMG_7753.webp";
import controlRoom7 from "../../assets/woodstockie/control_room/IMG_7755.webp";
import controlRoom8 from "../../assets/woodstockie/control_room/IMG_7786.webp";
import controlRoom9 from "../../assets/woodstockie/control_room/IMG_7787.webp";
import Stack from "@mui/material/Stack";


const controlImg = [
  controlRoom1,
  controlRoom2,
  controlRoom3,
  controlRoom4,
  controlRoom5,
  controlRoom6,
  controlRoom7,
  controlRoom8,
  controlRoom9,
  // Add more image paths as needed
];

export function ControlRoom() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Grid container sx={{ backgroundColor: "#B5BEC366" }}>

        <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", backgroundColor: "#024512" }}>
          <Grid item xs={12} sx={{ margin: "2vh 0 6vh 0" }}>
            <NavBar />
          </Grid>


          <Grid container sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <Grid item xs={12} md={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000", marginTop: "0vh" }}>
              <Stack direction={"column"}>
                <Typography
                  variant="h1"
                  sx={{ fontSize: "2.5em", marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #7BC24F, #FDF000)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}
                >
                  {" "} Control Room {" "}
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Enhance operational efficiency and coordination with control rooms tailored to your construction site's needs. Our converted container control rooms offer a seamless blend of functionality, mobility, and durability.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  <ul style={{ textAlign: "left", color: "#fff" }}>
                    <li>
                      <b>Customized Solutions:</b> Our container control rooms are designed to match your specific operational requirements and workflows.
                    </li>
                    <li>
                      <b>Mobility and Flexibility:</b> Easily relocate your control room as your construction site evolves, ensuring optimal efficiency.
                    </li>
                    <li>
                      <b>Advanced Technology Integration:</b> Seamlessly integrate state-of-the-art monitoring and communication systems for real-time control.
                    </li>
                    <li>
                      <b>Weather-Resistant Design:</b> Our containers are built to withstand harsh conditions, providing a secure environment for critical operations.
                    </li>
                    <li>
                      <b>Centralized Management: </b>Streamline communication and decision-making with a centralized hub for site operations.
                    </li>
                    <li>
                      <b>Cost-Efficient Solution:</b> Save costs compared to traditional construction while benefiting from a fully functional and reliable control room.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" }, paddingBottom: "3vh" }}>
                  Elevate your construction site management with container control rooms that combine cutting-edge technology and practicality. <b>Woodstock Projects</b> empowers your operations with inventive solutions.
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0vh"
              }}>
              <img src={controlRoomMain} alt="" style={{ width: "90%", borderRadius: "0.2vw", height: "40vh", objectFit: "cover" }} />
            </Grid>
          </Grid>
        </Grid>


        <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
          <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: 600, background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: "1.7rem" }}>
            Gallery
          </Typography>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
            {/* ... (previous code) */}
            {/* Loop through the images array and group them in rows of three */}
            {Array.from({ length: Math.ceil(controlImg.length / 3) }).map((_, rowIndex) => (
              <Grid key={rowIndex} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%", height: "100%" }}>
                  {/* Generate image components within the loop */}
                  {controlImg.slice(rowIndex * 3, rowIndex * 3 + 3).map((imagePath, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ margin: "0.5vw" }}>
                      <img className="imageCon" src={imagePath} />
                    </Grid>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>

        </Grid>
      </Grid>
    </>
  );
}
