// components/Home.js
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import "./classrooms.css";
import Grid from "@mui/material/Grid";
import NavBar from "../../layout/navbar/Navbar";
import { Typography } from "@mui/material";
import Maps from "../../components/maps/Maps";
import { Button, TextField } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import classroomImage from "../../assets/woodstockie/classroom/IMG_8857.webp";
import classroom1 from "../../assets/woodstockie/classroom/IMG_8857.webp";
// import classroom2 from "../../assets/woodstockie/classroom/IMG_8820.jpg";
import classroom3 from "../../assets/woodstockie/classroom/IMG_8812.webp";
// import classroom4 from "../../assets/woodstockie/classroom/IMG_8758.jpg";
import classroom5 from "../../assets/woodstockie/classroom/IMG_3308.webp";
import classroom6 from "../../assets/woodstockie/classroom/IMG_3291.webp";
import classroom7 from "../../assets/woodstockie/classroom/IMG_3284.webp";
import classroom8 from "../../assets/woodstockie/classroom/IMG_8842.jpg";
import classroom9 from "../../assets/woodstockie/classroom/IMG_1380.jpg";
import Stack from "@mui/material/Stack";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#183b25" : "#183b25",
  // padding: theme.spacing(31),
  textAlign: "center",
  boxShadow: "none",
}));

const controlImg = [
  classroom1,
  classroom9,
  classroom3,
  classroom8,
  classroom5,
  classroom6,
  classroom7,
  classroom8,
  classroom9,
  // Add more image paths as needed
];

export function Classrooms() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Grid container sx={{ backgroundColor: "#B5BEC366" }}>

        <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", backgroundColor: "#024512" }}>
          <Grid item xs={12} sx={{ margin: "2vh 0 6vh 0" }}>
            <NavBar />
          </Grid>


          <Grid container sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <Grid item xs={12} md={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000", marginTop: "0vh" }}>
              <Stack direction={"column"}>
                <Typography
                  variant="h1"
                  sx={{ fontSize: "2.5em", marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #7BC24F, #FDF000)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}
                >
                  {" "} Classrooms {" "}
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Create engaging and functional learning spaces with container solutions tailored for schools and educational institutions. <b>Woodstock Projects</b> provides versatile and inventive solutions to meet modern educational needs.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  <ul style={{ textAlign: "left", color: "#fff" }}>
                    <li>
                      <b>Dynamic Classrooms:</b> Transform standard containers into interactive classrooms that foster creativity and collaboration.
                    </li>

                    <li>
                      <b>Modular Design:</b> Adapt the space as educational needs evolve, whether for classrooms, libraries, or specialized labs.
                    </li>

                    <li>
                      <b>Quick Deployment:</b> Expedite the setup of temporary or permanent learning environments without compromising quality.
                    </li>

                    <li>
                      <b>Cost-Effective:</b> Save costs compared to traditional construction while delivering functional and inspiring educational spaces.
                    </li>

                    <li>
                      <b>Sustainable Solutions:</b> Embrace sustainable practices with eco-friendly materials and energy-efficient designs.
                    </li>

                    <li>
                      <b>Tailored Solutions:</b> We work closely with educational institutions to ensure our container solutions align with their vision.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" }, paddingBottom: "3vh" }}>
                  Elevate the learning experience with container-based solutions that prioritize adaptability, innovation, and student engagement. <b>Woodstock Projects</b> is your partner in transforming education spaces.
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}>
              <img src={classroomImage} alt="" style={{ width: "90%", borderRadius: "0.2vw", height: "40vh", objectFit: "cover" }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid className="image-container" xs={12} sx={{ marginTop: "6vh", marginBottom: "6vh" }}>
          <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: 600, background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: "1.7rem" }}>
            Gallery
          </Typography>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
            {/* ... (previous code) */}
            {/* Loop through the images array and group them in rows of three */}
            {Array.from({ length: Math.ceil(controlImg.length / 3) }).map((_, rowIndex) => (
              <Grid key={rowIndex} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%", height: "100%" }}>
                  {/* Generate image components within the loop */}
                  {controlImg.slice(rowIndex * 3, rowIndex * 3 + 3).map((imagePath, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ margin: "0.5vw" }}>
                      <img className="imageCon" src={imagePath} />
                    </Grid>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
