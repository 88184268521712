import "../../layout/footer/Footer.css";
import "./Footer.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { TextField } from "@aws-amplify/ui-react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Leonardo from "../../assets/images/Leonardo_Diffusion_a_silhouette_of_6_rows_of_shipping_containe_3.jpg"

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export function Footer() {
  return (
    <>
      <Stack direction={{ xs: "column", lg: "row" }} sx={{ margin: 0 }}>

        <Grid container className="footerContainer">
          <div className="grayOverlay"> </div>
          <div className="footerContent">
            <Grid container>
              <Grid item xs={12} md={4}>
                <Item sx={{ background: "transparent", color: "#fff", fontWeight: "normal" }}>
                  <Typography variant="h5" sx={{ textDecoration: "underline", marginBottom: "4vh", fontFamily: "Roboto" }}>Trading Hours</Typography>

                  <Typography variant="subtitle1" sx={{ fontFamily: "Poppins" }}>Monday - Thursday:</Typography>
                  <Typography variant="body1">7:00-17:00</Typography>

                  <Typography variant="subtitle1" sx={{ fontFamily: "Poppins" }}>Friday:</Typography>
                  <Typography variant="body1">7:00-15:00</Typography>

                  <Typography variant="subtitle1" sx={{ fontFamily: "Poppins" }}>Weekends:</Typography>
                  <Typography variant="body1"> Closed </Typography>
                </Item>

              </Grid>

              <Grid item xs={12} md={4} className="icons">
                <Item sx={{ background: "transparent", color: "#7BC24F", display: "flex", alignItems: "flex-end", justifyContent: "center", fontSize: "1.7em" }}>
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                  <i className="fa fa-facebook-square" aria-hidden="true"></i>
                </Item>
              </Grid>

              <Grid item xs={12} md={4} className="footerLinks">
                <Item sx={{ background: "transparent", color: "#fff", fontWeight: "normal" }}>
                  <Typography variant="h5" sx={{ textDecoration: "underline", marginBottom: "4vh", fontFamily: "Roboto" }}>Contact</Typography>

                  <Typography variant="body1">Plot 161, Hallgate, Nigel, 1490</Typography>
                  <Typography variant="body1">082 414 2858</Typography>
                  <Typography variant="body1">083 280 9079</Typography>
                  <Typography variant="body1"><a href="mailto:info@mysite.com" style={{ textDecoration: "none", color: "#fff" }}>corne@woodstockie.com</a></Typography>
                  <Typography variant="body1"><a href="mailto:info@mysite.com" style={{ textDecoration: "none", color: "#fff" }}>sales1@woodstockie.com</a></Typography>
                </Item>
              </Grid>

              <Grid item xs={12} sx={{ borderTop: "#7BC24F 0.3vh solid" }}>
                <Item sx={{ background: "transparent", color: "#fff", fontFamily: "Poppins", display: "flex", alignSelf: "center" }}>
                  <Typography variant="subtitle1"> Copyright &copy; Pyrrho Tech 2023</Typography>
                </Item>
              </Grid>
            </Grid>
          </div>
        </Grid>

      </Stack>
    </>
  );
}
