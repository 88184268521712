import { ContactUs } from "./pages/contact/contactUs";
import { FAQ } from "./pages/faq/faq";
import { Home } from "./pages/home/home";
import { Aluminium } from "./pages/aluminium/aluminium";
import { WContainer } from "./pages/container/container";
import { Storage } from "./pages/storage/storage";
import { Protected } from './pages/userHome/userHome';
import { ModernHouse } from "./pages/modernHomes/modernHomes";
import { ControlRoom } from "./pages/control_room/control_room";
import { Offices } from "./pages/offices/offices";
import { Restaurants } from "./pages/restaurants/restaurants";
import { Ablutions } from "./pages/ablutions/ablutions";
import { Classrooms } from "./pages/classrooms/classrooms";

export interface AppRoute{
    Navigation: string,
    DisplayName: string,
    Protected: boolean,
    Component: () => JSX.Element,
    NavBarVisibile: boolean
}

export const CustomRoutes : AppRoute[] = [
    {
        DisplayName: 'Home',
        Navigation: '/home',
        Protected: false,
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Containers',
        Navigation: '/containers',
        Protected: false,
        Component: WContainer,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Storage Units',
        Navigation: '/storage',
        Protected: false,
        Component: Storage,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Aluminium',
        Navigation: '/aluminium',
        Protected: false,
        Component: Aluminium,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Contact Us',
        Navigation: '/contact-us',
        Protected: false,
        Component: ContactUs,
        NavBarVisibile: true
    },
    {
        DisplayName: 'FAQ',
        Navigation: '/faq',
        Protected: false,
        Component: FAQ,
        NavBarVisibile: true
    },
    {
        DisplayName: 'modernHouse',
        Navigation: '/modernHomes',
        Protected: false,
        Component: ModernHouse,
        NavBarVisibile: false
    },
    {
        DisplayName: 'control_room',
        Navigation: '/control_room',
        Protected: false,
        Component: ControlRoom,
        NavBarVisibile: false
    },
    {
        DisplayName: 'offices',
        Navigation: '/offices',
        Protected: false,
        Component: Offices,
        NavBarVisibile: false
    },
    {
        DisplayName: 'restaurants',
        Navigation: '/restaurants',
        Protected: false,
        Component: Restaurants,
        NavBarVisibile: false
    },
    {
        DisplayName: 'ablutions',
        Navigation: '/ablutions',
        Protected: false,
        Component: Ablutions,
        NavBarVisibile: false
    },
    {
        DisplayName: 'classrooms',
        Navigation: '/classrooms',
        Protected: false,
        Component: Classrooms,
        NavBarVisibile: false
    }
]

export const SettingRoutes : AppRoute[] = [
    {
        DisplayName: 'Profile',
        Navigation: '/profile',
        Protected: true,
        Component: Protected,
        NavBarVisibile: true
    },
]