// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { RingLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './faq.css';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Container from '@mui/material/Container';
import NavBar from "../../layout/navbar/Navbar";
import { Grid } from '@mui/material';

export function FAQ() {
  const [isLoaded, setIsLoaded] = useState(true)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    // const getData = async () => {

    //   setNotificationID(Store.addNotification({
    //     title: "Wonderful!",
    //     message: "teodosii@react-notifications-component",
    //     type: "warning",
    //     insert: "top",
    //     container: "top-left",
    //     animationIn: ["animate__animated", "animate__fadeIn"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     dismiss: {
    //       duration: 2000,
    //       onScreen: true
    //     }
    //   }));

    //   Store.removeNotification(notificationID)
    // await delay(1000);
    setIsLoaded(true)

    // }
    // getData()
  }, [])


  return (
    <>
      {isLoaded ? (
        <>
          <Grid container>
            <Grid container sx={{ borderRadius: {xs: "5vw", lg: "0.6vw"}, margin: "2vh 3vw", backgroundColor: "#024512", verticalAlign: "center", height: "15vh", paddingY: "2vh"}}>
              <NavBar />
            </Grid>

            <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>

              <Typography variant="h3"
                sx={{ fontSize: "3rem", marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}>
                {" "}
                Frequently Asked Questions
              </Typography>
            </Grid>

            <Grid container>
              <Container sx={{ marginBottom: "6vh" }}>
                <div className='accordion-con'>
                  <Accordion sx={{ marginBottom: "1.5vh" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "#7BC24F" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 15, sm: 16, md: 18, lg: 20, xl: 20 } }}>
                        <QuestionMarkIcon sx={{ marginRight: 1 }} />
                        How can container solutions benefit my business?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 17, xl: 19 } }}>
                        Container solutions offer remarkable versatility. From creating mobile offices to pop-up shops, they provide cost-effective and customizable spaces that adapt to your business needs.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion sx={{ marginBottom: "1.5vh" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "#7BC24F" }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 } }}>
                        <QuestionMarkIcon sx={{ marginRight: 1 }} />
                        Can containers be customized to match my brand's aesthetic?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                        Absolutely. Our container solutions are fully customizable. You can choose colors, branding, and interior layouts that align perfectly with your brand's identity.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion sx={{ marginBottom: "1.5vh" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "#7BC24F" }} />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 } }}>
                        <QuestionMarkIcon sx={{ marginRight: 1 }} />
                        Are container solutions eco-friendly?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                        Yes, container solutions are eco-friendly alternatives. They repurpose shipping containers, reducing waste and supporting sustainable construction practices.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  {/* Add more container-related questions here... */}

                </div>
              </Container>

            </Grid>
          </Grid>

        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><RingLoader color="#36d7b7" /></div>

        </div>


      )}
    </>


  );
}
