import { Authenticator } from '@aws-amplify/ui-react';

import { Login } from './components/auth/Login';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import { Home } from './pages/home/home';
import { RequireAuth } from './components/auth/RequireAuth';
import { Layout } from './layout/Layout';
import { AppRoute, CustomRoutes, SettingRoutes } from './routes'
import { NotFoundPage } from './pages/errors/400';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { createTheme, colors, ThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    }, secondary: {
      main: "#56C685"
    }
  }
})

function MyRoutes() {
 

  return (
    < >
      <ReactNotifications />

      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Layout />}>

            {/* Default index page */}
            <Route index element={<Home />} />

            {/* These get loaded from the routes. */}
            {CustomRoutes.map((cRo: AppRoute) => {

              return (
                <Route
                  key={cRo.DisplayName}
                  path={cRo.Navigation}
                  element={cRo.Protected ?
                    <RequireAuth>
                      <cRo.Component />
                    </RequireAuth> :
                    <cRo.Component />}
                />
              )
            })}

            {SettingRoutes.map((setting: AppRoute) => (
              <Route
                key={setting.DisplayName}
                path={setting.Navigation}
                element={setting.Protected ?
                  <RequireAuth>
                    <setting.Component />
                  </RequireAuth> :
                  <setting.Component />}
              />
            ))}
            <Route path="/login" element={<Login />} />
            {/* Catches any routes not selected */}
            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Authenticator.Provider >
        <MyRoutes />
      </Authenticator.Provider>
    </ThemeProvider>
  );
}

export default App;