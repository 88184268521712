// components/Home.js
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import "./restaurants.css";
import Grid from "@mui/material/Grid";
import NavBar from "../../layout/navbar/Navbar";
import { Typography } from "@mui/material";
import Maps from "../../components/maps/Maps";
import { Button, TextField } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import restaurantMain from "../../assets/woodstockie/pizza/ec9dba35-e1d6-44fb-9a7d-0936107f77bf.webp";
import restaurant1 from "../../assets/woodstockie/pizza/a041360b-6239-435b-92c7-3edf5048b853.webp";
import restaurant2 from "../../assets/woodstockie/pizza/cac38665-db9c-40ba-93df-3fe4acb9ea0d.webp";
import restaurant3 from "../../assets/woodstockie/pizza/d8cd2100-a9dd-42c9-8b21-375050d5651e.webp";
import restaurant4 from "../../assets/woodstockie/pizza/ec9dba35-e1d6-44fb-9a7d-0936107f77bf.webp";
import restaurant5 from "../../assets/woodstockie/pizza/a041360b-6239-435b-92c7-3edf5048b853.webp";
import restaurant6 from "../../assets/woodstockie/pizza/cac38665-db9c-40ba-93df-3fe4acb9ea0d.webp";
import restaurant7 from "../../assets/woodstockie/pizza/d8cd2100-a9dd-42c9-8b21-375050d5651e.webp";
import restaurant8 from "../../assets/woodstockie/pizza/ec9dba35-e1d6-44fb-9a7d-0936107f77bf.webp";
import restaurant9 from "../../assets/woodstockie/pizza/a041360b-6239-435b-92c7-3edf5048b853.webp";
import Stack from "@mui/material/Stack";

const imgGroup = [
  restaurant1,
  restaurant2,
  restaurant3,
  restaurant4,
  restaurant5,
  restaurant6,
  restaurant7,
  restaurant8,
  restaurant9,
  // Add more image paths as needed
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#183b25" : "#183b25",
  // padding: theme.spacing(31),
  textAlign: "center",
  boxShadow: "none",
}));

export function Restaurants() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Grid container sx={{ backgroundColor: "#B5BEC366" }}>

        <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", backgroundColor: "#024512" }}>
          <Grid item xs={12} sx={{ margin: "2vh 0 6vh 0" }}>
            <NavBar />
          </Grid>


          <Grid container sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <Grid item xs={12} md={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000", marginTop: "0vh" }}>
              <Stack direction={"column"}>
                <Typography
                  variant="h1"
                  sx={{ fontSize: "2.5em", marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #7BC24F, #FDF000)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}
                >
                  {" "} Restaurants {" "}
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Elevate the dining experience with inventive container-based restaurant solutions from <b>Woodstock Projects.</b> Whether you're looking for pop-up eateries or fully equipped kitchens, our custom solutions redefine restaurant design.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  <ul style={{ textAlign: "left", color: "#fff" }}>
                    <li>
                      <b>Creative Concepts: </b>Embrace unique restaurant designs that capture attention and create memorable dining experiences.
                    </li>

                    <li>
                      <b>Flexibility and Mobility:</b> Set up temporary or permanent restaurants in diverse locations, enhancing customer reach.
                    </li>

                    <li>
                      <b>Customized Kitchens:</b> Design fully functional kitchens optimized for efficiency, whether for gourmet dining or fast food.
                    </li>

                    <li>
                      <b>Sustainable Practices:</b> Choose eco-friendly materials and designs that align with your restaurant's commitment to sustainability.
                    </li>

                    <li>
                      <b>Brand Identity:</b> Showcase your brand's personality through personalized container interiors and exteriors.
                    </li>

                    <li>
                      <b>Cost-Effective Solution:</b> Save on construction costs while creating captivating restaurant spaces that stand out.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" }, paddingBottom: "3vh" }}>
                  Redefine restaurant design with container solutions that combine aesthetics, functionality, and sustainability. <b>Woodstock Projects</b> turns your culinary vision into a reality.
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0vh"
              }}>
              <img src={restaurantMain} alt="" style={{ width: "90%", borderRadius: "0.2vw", height: "40vh", objectFit: "cover" }} />
            </Grid>

          </Grid>
        </Grid>




        <Grid className="image-container" xs={12} sx={{ marginTop: "6vh", marginBottom: "6vh" }}>
          <Typography variant="h5" mb={4} sx={{
            fontFamily: "Poppins", fontVariant: "small-caps", background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 500, fontSize: "1.7rem"
          }}>
            Gallery
          </Typography>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
            {/* ... (previous code) */}
            {/* Loop through the images array and group them in rows of three */}
            {Array.from({ length: Math.ceil(imgGroup.length / 3) }).map((_, rowIndex) => (
              <Grid key={rowIndex} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%", height: "100%" }}>
                  {/* Generate image components within the loop */}
                  {imgGroup.slice(rowIndex * 3, rowIndex * 3 + 3).map((imagePath, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ margin: "0.5vw" }}>
                      <img className="imageCon" src={imagePath} />
                    </Grid>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
