// components/Home.js
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import "../aluminium/aluminium.css";
import Grid from "@mui/material/Grid";
import NavBar from "../../layout/navbar/Navbar";
import { Typography } from "@mui/material";
import Maps from "../../components/maps/Maps";
import { Button, TextField } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import aluminiumMain from "../../assets/woodstockie/aluminium/Aluminium8.webp";
import aluminium1 from "../../assets/woodstockie/aluminium/Aluminium7.webp";
import aluminium2 from "../../assets/woodstockie/aluminium/Aluminium8.webp";
import aluminium3 from "../../assets/woodstockie/aluminium/Aluminium9.webp"
import aluminium4 from "../../assets/woodstockie/aluminium/Aluminium10.webp";
import aluminium5 from "../../assets/woodstockie/aluminium/Aluminium11.webp";
import aluminium6 from "../../assets/woodstockie/aluminium/Aluminium12.webp";
import aluminium7 from "../../assets/woodstockie/aluminium/Aluminium14.webp";
import aluminium8 from "../../assets/woodstockie/aluminium/Aluminium6.webp";
import aluminium9 from "../../assets/woodstockie/aluminium/Aluminium5.webp";
import Stack from "@mui/material/Stack";

const aluminiumImages = [
  aluminium1,
  aluminium2,
  aluminium3,
  aluminium4,
  aluminium5,
  aluminium6,
  aluminium7,
  aluminium8,
  aluminium9
];

export function Aluminium() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Grid container sx={{ backgroundColor: "#B5BEC366" }}>

        <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", backgroundColor: "#024512" }}>
          <Grid item xs={12} sx={{ margin: "2vh 0 6vh 0" }}>
            <NavBar />
          </Grid>


          <Grid container sx={{ display: "flex", justifyContent: "space-evenly", margin: { xs: 0, md: "15vh auto", lg: 0 } }}>
            <Grid item xs={12} md={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000" }}>
              <Stack direction={"column"}>
                <Typography
                  variant="h1"
                  sx={{ fontSize: { xs: "2rem", lg: "3rem" }, marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #7BC24F, #FDF000)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}
                >
                  {" "} Aluminium {" "}
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Step into a world of exceptional Aluminum craftsmanship with Woodstock Projects. From exquisite Aluminum Windows to inviting Storefronts, we offer a range of tailored solutions that harmonize aesthetics and functionality. Our commitment shines through in every detail:
                </Typography>
                <br/>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                <ul style={{ textAlign: "left", color: "#fff" }}>
                  <li>
                    <b>Affordable Excellence:</b> We believe in delivering premium Aluminum products without breaking the bank. Our competitive pricing ensures you get the best value for your investment.
                  </li>
                  <li>
                    <b>Custom Craftsmanship:</b> Your vision is our blueprint. Our skilled artisans create custom-made Aluminum solutions that seamlessly integrate into your space, reflecting your unique style and needs.
                  </li>
                  <li>
                    <b>Quality Beyond Compromise:</b> Only the finest materials meet our standards. With a focus on durability and longevity, our Aluminum products are crafted to withstand the test of time.
                  </li>
                  <li>
                    <b>Swift and Reliable:</b> Time is of the essence, and we honor that. Our fast service ensures that your Aluminum projects are executed efficiently, without compromising on quality.
                  </li>
                </ul>
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" }, paddingBottom: "3vh" }}>
                  Experience the difference that exceptional Aluminum solutions can make in your spaces. Discover the art of balancing affordability, customization, and quality, exclusively with Woodstock Projects.
                </Typography>

              </Stack>
            </Grid>

            <Grid item xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
              <img src={aluminiumMain} alt="" style={{ width: "90%", borderRadius: "0.2vw", height: "80%", objectFit: "cover" }} />
            </Grid>
          </Grid>
        </Grid>


        <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
          <Typography variant="h5" mb={4} sx={{
            fontFamily: "Poppins", fontVariant: "small-caps", background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 500, fontSize: "1.7rem"
          }}>
            Gallery
          </Typography>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh", width: "100%" }}>
            {/* ... (previous code) */}

            {/* Loop through the images array and group them in rows of three */}
            {Array.from({ length: Math.ceil(aluminiumImages.length / 3) }).map((_, rowIndex) => (
              <Grid key={rowIndex} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }}>
                  {/* Generate image components within the loop */}
                  {aluminiumImages.slice(rowIndex * 3, rowIndex * 3 + 3).map((imagePath, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ margin: "0.5vw", objectFit: "contain" }}>
                      <img className="imageCon" src={imagePath} />
                    </Grid>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>


        </Grid>
      </Grid >
    </>
  );
}
