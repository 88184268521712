// components/Home.js
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import "./offices.css";
import Grid from "@mui/material/Grid";
import NavBar from "../../layout/navbar/Navbar";
import { Typography } from "@mui/material";
import Maps from "../../components/maps/Maps";
import { Button, TextField } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import officeImage from "../../assets/woodstockie/offices/IMG_1203.jpg";
import office1 from "../../assets/woodstockie/offices/IMG_1203.jpg";
import office2 from "../../assets/woodstockie/offices/IMG_1372.jpg";
import office3 from "../../assets/woodstockie/offices/IMG_2517.jpg";
import office4 from "../../assets/woodstockie/offices/IMG_2561.jpg";
import office5 from "../../assets/woodstockie/offices/IMG_2565.jpg";
import office6 from "../../assets/woodstockie/offices/IMG_2567.jpg";
import office7 from "../../assets/woodstockie/offices/IMG_2569.jpg";
import office8 from "../../assets/woodstockie/offices/IMG_8938.jpg";
import office9 from "../../assets/woodstockie/offices/PHOTO-2022-03-06-07-41-13.jpg";
import Stack from "@mui/material/Stack";

const imgGroup = [
  office1,
  office2,
  office3,
  office4,
  office5,
  office6,
  office7,
  office8,
  office9,
  // Add more image paths as needed
];

export function Offices() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Grid container sx={{ backgroundColor: "#B5BEC366" }}>

        <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", backgroundColor: "#024512" }}>
          <Grid item xs={12} sx={{ margin: "2vh 0 6vh 0" }}>
            <NavBar />
          </Grid>


          <Grid container sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <Grid item xs={12} md={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000", marginTop: "0vh" }}>
              <Stack direction={"column"}>
                <Typography
                  variant="h1"
                  sx={{ fontSize: "2.5em", marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #7BC24F, #FDF000)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}
                >
                  {" "} Offices {" "}
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Elevate productivity and collaboration with container-based office solutions tailored to your organization's needs. <b>Woodstock Projects</b> offers Inventive designs that optimize space, flexibility, and functionality.
                </Typography>
                <br/>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                <ul style={{ textAlign: "left", color: "#fff" }}>
                  <li>
                    <b>Customized Workspaces:</b> Design offices that reflect your brand identity and enhance employee satisfaction.
                  </li>
                  <li>
                    <b>Flexible Configurations:</b> Adapt the workspace as your organization grows, ensuring maximum efficiency.
                  </li>
                  <li>
                    <b>Modular Design:</b> Optimize space with modular container layouts that accommodate various office functions.
                  </li>
                  <li>
                    <b>Sustainable Spaces:</b> Choose eco-friendly materials and energy-efficient designs that align with your values.
                  </li>
                  <li>
                    <b>Collaboration Hubs:</b> Foster teamwork with open and collaborative office environments that inspire creativity.
                  </li>
                  <li>
                    <b>Cost-Effective Solutions:</b> Save on construction costs while creating modern, functional, and adaptable office spaces.
                  </li>
                </ul>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" }, paddingBottom: "3vh" }}>
                  Transform your office into a hub of innovation with container solutions that combine design, flexibility, and sustainability. <b>Woodstock Projects</b> empowers your workspace to evolve with your organization.
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "0vh"
              }}>
              <img src={officeImage} alt="" style={{ width: "90%", borderRadius: "0.2vw", height: "40vh", objectFit: "cover" }} />
            </Grid>
          </Grid>
        </Grid>




        <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
          <Typography variant="h5" mb={4} sx={{
            fontFamily: "Poppins", fontVariant: "small-caps"
          }}>
            Gallery
          </Typography>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
            {/* ... (previous code) */}
            {/* Loop through the images array and group them in rows of three */}
            {Array.from({ length: Math.ceil(imgGroup.length / 3) }).map((_, rowIndex) => (
              <Grid key={rowIndex} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%", height: "100%" }}>
                  {/* Generate image components within the loop */}
                  {imgGroup.slice(rowIndex * 3, rowIndex * 3 + 3).map((imagePath, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ margin: "0.5vw" }}>
                      <img className="imageCon" src={imagePath} />
                    </Grid>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
