import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIconOutlined from '@mui/icons-material/LocationOnOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Maps from '../../components/maps/Maps';
import { BounceLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import NavBar from "../../layout/navbar/Navbar";
import { Button, Divider, Grid, IconButton, TextField } from '@mui/material';
import { useFormControls } from './contactFormControls';
import "./contactUs.css";
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: 'solid 2px #7BC24F'
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#000'
    },
  },
}));


export function ContactUs() {
  const customStyle = {
    color: 'blue',
    fontSize: '24px',
  };

  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    const getData = async () => {
      setIsLoaded(true)

    }
    getData()
  }, [])

  const inputFieldValues = [
    {
      name: "first_name",
      label: "Name",
      id: "my-name",
      width: "100%",
      padding: "5%"
    },
    {
      name: "last_name",
      label: "Last Name",
      id: "my-lastname",
      width: "100%"
    },
    {
      name: "email",
      label: "Email",
      id: "my-email",
      width: "100%"
    },
    {
      name: "title",
      label: "Title",
      id: "my-title",
      width: "100%"
    },
    {
      name: "message",
      label: "Message",
      id: "my-message",
      width: "100%",
      multiline: true,
      rows: 4
    },
    {
      name: "company_name",
      label: "Company Name",
      id: "company_name",
      width: "100%"
    },
    
  ];

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values
  } = useFormControls();

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container>
            <Grid container sx={{ borderRadius: {xs: "5vw", lg: "0.6vw"}, margin: "2vh 3vw", backgroundColor: "#024512", verticalAlign: "center", height: "15vh", paddingY: "2vh"}}>
              <NavBar />
            </Grid>

            <Grid container xs={12} sx={{ margin: "2vh auto 7vh auto", display: "flex", justifyContent: "space-around", alignItems: "baseline" }}>
              <Grid container>
                <Typography variant="h1" sx={{ margin: "6vh auto", fontSize: "4rem", fontWeight: 500, background: "-webkit-linear-gradient(90deg, #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>
                  Contact
                </Typography>
              </Grid>

              <Grid item lg={6} md={6} xs={12} sx={{ minHeight: "70vh", paddingTop: "0vh", margin: {xs: "auto auto 0vh auto", sm: "auto auto -13vh auto", lg: "auto auto 0 auto"} }}>
                <Grid xs={12} container sx={{ textAlign: "left", display: "flex", justifyContent: "center", margin: "0vh auto" }}>
                  <Grid item xs={3} sx={{ color: "#7BC24F" }}><LocationOnIconOutlined /></Grid>
                  <Grid item xs={6} lg={4}><a href="mailto:info@mysite.com" style={{ textDecoration: "none", color: "#7BC24F" }}> Plot 161, Hallgate, Nigel, 1490 </a></Grid>
                </Grid>
                <br /> <br />

                {/* Email */}
                <Grid xs={12} container sx={{ textAlign: "left", display: "flex", justifyContent: "center", margin: "0vh auto" }}>
                  <Grid item xs={3} sx={{ color: "#7BC24F" }}><MailOutlineIcon /></Grid>
                  <Grid item xs={6} lg={4}><a href="mailto:info@mysite.com" style={{ textDecoration: "none", color: "#7BC24F" }}>corne@woodstockie.com</a>
                  </Grid>
                </Grid>
                <br /> <br />

                {/* Whatsapp */}
                <Grid xs={12} container sx={{ textAlign: "left", display: "flex", justifyContent: "center", margin: "0vh auto" }}>
                  <Grid item xs={3} sx={{ color: "#7BC24F" }}><WhatsAppIcon /></Grid>
                  <Grid item xs={6} lg={4}><a href="https://wa.me/27818212245" style={{ textDecoration: "none", color: "#7BC24F" }}> 082 414 2858 </a></Grid>
                </Grid>
                <br /> <br />

                {/* Social */}
                <Grid container xs={12} sx={{ textAlign: "left", display: "flex", justifyContent: { xs: "space-evenly", sm: "invert", lg: "center" }, alignItems: "center", margin: { xs: "0vh auto", sm: "auto -2vw", lg: "0vh auto" } }}>
                  <Grid item xs={3}><ThumbUpOffAltIcon sx={{ color: "#7BC24F" }} /></Grid>
                  <Grid item xs={4} lg={4}>
                    <IconButton aria-label="Facebook.com" onClick={() => window.open('https://www.facebook.com/woodstockprojects/')} sx={{ color: "#7BC24F" }}>
                      <FacebookIcon />
                    </IconButton>

                    {/* <IconButton aria-label="Instagram.com" onClick={() => window.open('https://www.instagram.com/parkshare_app/?hl=en')} sx={{ color: "#7BC24F" }}>
                      <InstagramIcon />
                    </IconButton> */}
                  </Grid>
                </Grid>
              </Grid>
              

              <Grid item lg={6} md={6} xs={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", margin: {xs: "-20vh auto", md: 0} }} >
                <form autoComplete="off"
                onSubmit={handleFormSubmit}
                >
                  {inputFieldValues.map((inputFieldValue, index) => {
                    return (
                      <TextField className={classes.focusedTextField}
                        key={index}
                        onChange={handleInputValue}
                        onBlur={handleInputValue}
                        name={inputFieldValue.name}
                        label={inputFieldValue.label}
                        error={errors[inputFieldValue.name]}
                        multiline={inputFieldValue.multiline ?? false}
                        style={{ width: (inputFieldValue.width), paddingBottom: "5%" }}
                        value={(inputFieldValue.name in values) ? values[inputFieldValue.name] : undefined}
                        rows={inputFieldValue.rows ?? 1}
                        autoComplete="none"
                        {...(errors[inputFieldValue.name] && {
                          error: true,
                          helperText: errors[inputFieldValue.name]
                        })}
                      />
                    );
                  })}
                  <br /><br />
                  <div style={{ width: "100%", justifyContent: "right", alignContent: "right" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      disabled={!formIsValid()}
                      style={{ backgroundColor: "#7BC24F", color: "white", marginRight: "5px" }}
                    >
                      Send Message
                    </Button>
                  </div>
                </form>
              </Grid>
            </Grid>

            <Grid container sx={{ minHeight: "50vh", margin: { xs: "20vh auto 5vh auto", sm: "6vh auto", lg: "7vh auto 10vh auto" }, border: "3px solid #7BC24F", borderRadius: "0.2vw", padding: "1vh", width: { xs: "90%", lg: "82%" } }}>
              <Maps lat={-26.4304052} lng={28.53167} heigh={"500px"} width={"100%"} />
            </Grid>

          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><BounceLoader color="#8dc74a" cssOverride={{}} loading size={100} /></div>

        </div>
      )
      }
    </>


  );
}
