// components/Home.js
import { RingLoader } from "react-spinners";
import Maps from "../../components/maps/Maps";
import { Store } from "react-notifications-component";
import { useEffect, useRef, useState } from "react";
import { Button, TextField } from "@mui/material";
import companyLogo from "../../assets/images/woodstockieLogo.jpeg";
import "./home.css";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import homeImage1 from "../../assets/woodstockie/offices/IMG_1203.jpg";
import homeImage2 from "../../assets/woodstockie/pizza/ec9dba35-e1d6-44fb-9a7d-0936107f77bf.webp";
import homeImage3 from "../../assets/woodstockie/classroom/IMG_8857.webp";
import homeImage4 from "../../assets/woodstockie/control_room/IMG_4808.webp";
import homeImage5 from "../../assets/woodstockie/home/PHOTO-2021-11-13-13-06-23.jpg";
import homeImage6 from "../../assets/woodstockie/store/PHOTO-2022-03-06-07-38-23.jpg";
import homeImage7 from "../../assets/woodstockie/ablutions/IMG_1197.webp";
import Stack from "@mui/material/Stack";
// import { Auth } from 'aws-amplify';
import NavBar from "../../layout/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { CallsHelper } from "../../data/utils/Calls";

const colors = [homeImage1, homeImage2, homeImage3, homeImage4, homeImage5, homeImage6, homeImage7];
const delay = 2500;


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#024512" : "#024512",
  // padding: theme.spacing(31),
  textAlign: "center",
  boxShadow: "none",
}));

export function Home() {

  const initialFormValues: { [key: string]: string } = {
    first_name: "",
    email: "",
    message: "",
  };


  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(true);
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as any);
  const [notificationID, setNotificationID] = useState("");
  var i = 0;
  var images: string | any[] = [];
  var time = 3000;

  images[0] = "../../assets/images/abultion.jpeg";
  images[1] = "../../assets/images/abultion2.jpeg";
  images[2] = "../../assets/images/bedroom.jpeg";

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<ReturnType<typeof setInterval> | null>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();

    const nextIndex = index === colors.length - 1 ? 0 : index + 1;

    timeoutRef.current = setTimeout(() => {
      setIndex(nextIndex);
    }, delay);

    return () => {
      resetTimeout();
    };
  }, [index]);

  const handleRedirect = () => {
    // Replace "/destination" with the actual URL you want to redirect to
    navigate("/contact-us");
  };


  const validate: any = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name ? "" : "This field is required.";

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";

    if ("message" in fieldValues)
      temp.message =
        fieldValues.message.length !== 0 ? "" : "This field is required.";

    setErrors({
      ...temp
    });
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    console.log("Updating:", name)
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const handleSuccess = () => {
    setValues({
      ...initialFormValues
    });
  };

  const handleError = () => {
    setValues({
      ...initialFormValues
    });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.first_name &&
      fieldValues.email &&
      fieldValues.message &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  const handleFormSubmit = async (e: any) => {
    console.log("Submitting")
    validate()
    e.preventDefault();
    const isValid =
      Object.values(errors).every((x) => x === "") && formIsValid();
    console.log(isValid)
    if (isValid) {
      const callsHelper = new CallsHelper();
      callsHelper.postItemNoAuth(`/contact`, values)
        .then(() => {
          // Success case
          console.log("Form submitted successfully");
          handleSuccess();
  
          // Clear the form values
          console.log("Clearing form values to initialFormValues:", initialFormValues);
          setValues({
            ...initialFormValues
          });
  
          setNotificationID(
            Store.addNotification({
              title: "Message sent!",
              message: "Your message has been sent. We will get back to you.",
              type: "success",
              insert: "top",
              container: "top-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            })
          );
        })
        .catch(() => {
          // Error case
          console.log("Form submission failed");
          handleError();
          setNotificationID(
            Store.addNotification({
              title: "Oops!",
              message: "We had a problem submitting your message. Our team will look at this.",
              type: "danger",
              insert: "top",
              container: "top-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            })
          );
        });
    }
  };

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container sx={{ backgroundColor: "#B5BEC366", width: { xs: "95%", lg: "100%" }, margin: "auto" }}>

            <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", backgroundColor: "#024512" }}>
              <Grid container xs={12} sx={{ margin: "2vh 0 6vh 0" }}>
                <NavBar />
              </Grid>


              <Grid container sx={{ display: "flex", justifyContent: "space-evenly", margin: { xs: 0, md: "15vh auto", lg: 0 } }}>
                <Grid item xs={12} md={5}
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000" }}>
                  <Stack direction={"column"} className="text">
                    <Typography
                      variant="h1"
                      sx={{ fontSize: { xs: "2rem", lg: "4rem" }, marginBottom: "3vh", color: "#fff", fontWeight: 600, marginLeft: "-3.3vw" }}
                    >
                      Woodstock Projects
                    </Typography>
                    <Typography
                      id="description"
                      variant="h6"
                      sx={{
                        color: "#fff",
                        fontSize: { xs: "1.1rem", lg: "2rem" },
                        padding: { xs: "1vh", lg: 0 },
                        margin: { xs: "0 auto", lg: 0 },
                        // Center on xs screens, left-align on lg screens
                      }}
                    >
                      SA's leaders in customised modular solutions!
                    </Typography>
                    <Button
                      sx={{
                        width: "fit-content", // Button's width will adjust to its content
                        padding: "1rem 2rem",
                        borderRadius: "0.6rem",
                        marginY: "1rem", // Adjust vertical margin
                        fontSize: "1rem",
                        margin: { xs: "0 auto", md: 0 }
                      }}
                      onClick={handleRedirect}
                    >
                      Contact us
                    </Button>
                  </Stack>
                </Grid>

                <Grid item xs={12} lg={6} sx={{ display: "flex", justifyContent: "center", marginTop: "3vh" }}>
                  <Item className="image-slide">
                    <div className="slideshow">
                      <div
                        className="slideshowSlider"
                        style={{
                          transform: `translate3d(${-(index === colors.length - 1 ? colors.length - 1 : index) * 100}%, 0, 0)`,
                        }}
                      >
                        {colors.map((url, index) => (
                          <img className="slide" key={index} src={url} />
                        ))}
                      </div>

                      <div className="slideshowDots">
                        {colors.map((_, idx) => (
                          <div
                            key={idx}
                            className={`slideshowDot${index === idx ? " active" : ""
                              }`}
                            onClick={() => {
                              setIndex(idx);
                            }}
                          ></div>
                        ))}
                      </div>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Grid>

            <Box
              className="services"
              sx={{
                flexGrow: 1,
                background: "#fff",
                color: "#000",
                fontFamily: "Poppins",
                padding: "4%",
                paddingTop: "2%",

              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                  color: "#002C3D",
                  marginBottom: "3vh"
                }}
              >
                <Typography variant="h5" sx={{ fontFamily: "Poppins", fontWeight: 600, background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: "1.7rem" }}>
                  {" "}
                  What we can do!{" "}
                </Typography>
              </Grid>

              <Grid container className="values">
                <Grid item xs={12} sm={1.713} md={4} lg={1.713} sx={{ marginTop: "2vh", marginBottom: "2vh" }}>
                  <span className="material-symbols-outlined" style={{ color: "#7BC24F" }}>kitchen</span>
                  <Typography variant="body1">Kitchen Units</Typography>
                </Grid>

                <Grid item xs={12} sm={1.713} md={4} lg={1.713} sx={{ marginTop: "2vh", marginBottom: "2vh" }}>
                  <span className="material-symbols-outlined" style={{ color: "#7BC24F" }}>assignment</span>
                  <Typography variant="body1">Offices</Typography>
                </Grid>

                <Grid item xs={12} sm={1.713} md={4} lg={1.713} sx={{ marginTop: "2vh", marginBottom: "2vh" }}>
                  <span className="material-symbols-outlined" style={{ color: "#7BC24F" }}>school</span>
                  <Typography variant="body1">Class Training Rooms</Typography>
                </Grid>

                <Grid item xs={12} sm={1.713} md={4} lg={1.713} sx={{ marginTop: "2vh", marginBottom: "2vh" }}>
                  <span className="material-symbols-outlined" style={{ color: "#7BC24F" }}>science</span>
                  <Typography variant="body1">Laboratories</Typography>
                </Grid>

                <Grid item xs={12} sm={1.713} md={4} lg={1.713} sx={{ marginTop: "2vh", marginBottom: "2vh" }}>
                  <span className="material-symbols-outlined" style={{ color: "#7BC24F" }}>apartment</span>
                  <Typography variant="body1">Accommodation</Typography>
                </Grid>

                <Grid item xs={12} sm={1.713} md={4} lg={1.713} sx={{ marginTop: "2vh", marginBottom: "2vh" }}>
                  <span className="material-symbols-outlined" style={{ color: "#7BC24F" }}>wc</span>
                  <Typography variant="body1">Ablutions</Typography>
                </Grid>

                <Grid item xs={12} sm={1.713} md={4} lg={1.713} sx={{ marginTop: "2vh", marginBottom: "2vh" }}>
                  <span className="material-symbols-outlined" style={{ color: "#7BC24F" }}>warehouse</span>
                  <Typography variant="body1">Self Storage</Typography>
                </Grid>
                <Grid item xs={12} sm={1.713} md={4} lg={1.713} sx={{ marginTop: "2vh", marginBottom: "2vh" }}>
                  <span className="material-symbols-outlined" style={{ color: "#7BC24F" }}>factory</span>
                  <Typography variant="body1">MCC</Typography>
                </Grid>
              </Grid>
            </Box>

            <Grid
              container
              sx={{

                background: "#B5BEC366",
                display: "flex",
                justifyContent: "space-evenly",
                padding: "1.8vw"
              }}
            >
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img className="logo" src={companyLogo} alt="" />
              </Grid>

              <Grid item xs={12} md={5} sx={{ color: "#000" }}>
                <Stack direction={"column"} sx={{ textAlign: "left" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Orbitron",
                      fontSize: "2em",
                      margin: { xs: "4vh auto", lg: "2%" },
                    }}
                  >
                    About Us
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Work Sans",
                      alignSelf: "center",
                      marginLeft: { xs: "0", lg: "3%" },
                      padding: { xs: "0vh 4vw", lg: 0 },
                    }}
                  >
                    Unlocking Possibilities with Containers: Your Ideal Partner for Custom Conversions and Inventive Solutions.
                    <br /><br />
                    Discover a world of limitless potential with us. At Woodstock Projects, we're driven by a passion for delivering exceptional container conversions that redefine space and functionality. Our team of dedicated experts is committed to creating bespoke structures that perfectly match your needs.
                    <br /><br />
                    Explore a comprehensive range of services, from container sales to tailored conversions. We pride ourselves on offering end-to-end solutions that redefine affordability without compromising quality.
                    <br /><br />
                    Turn your vision into reality with Woodstock Projects. Our skilled craftsmen ensure top-tier workmanship, transforming containers into versatile spaces. We excel in converting containers into various configurations, offering storage solutions, and specializing in electrical and aluminum work.
                    <br /><br />
                    Experience innovation, expertise, and excellence with Woodstock Projects. Your journey towards inventive container solutions begins here.
                  </Typography>

                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              className="services"
              sx={{
                background: "#B5BEC344",
                padding: "2vw",
                fontFamily: "Outfit",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{ fontFamily: "Poppins", color: "#000" }}
              >
                <Stack direction={"column"}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.3rem",
                      margin: { xs: "1vh", lg: "intial" },
                    }}
                  >
                    Get a Quote from{" "}
                    <span className="companyName">Woodstock</span>
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      textAlign: "left",
                      fontSize: "1.1em",
                      marginTop: { xs: "2vh", lg: "1vh" },
                      padding: { xs: "0vh 4vw", lg: 0 }
                    }}
                  >
                    Contact us today for whatever purpose you need! We offer container conversions, aluminium windows and doors and storage units.
                  </Typography>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  color: "#000",
                  marginTop: "2vh",
                  marginBottom: "2vh",
                  margin: { xs: "3vh", lg: "initial" },
                }}
              >
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Grid item xs={12} md={12} sx={{}}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        display: "flex", justifySelf: "flex-start",
                        marginBottom: "2vh"
                      }}
                    >
                      {" "}
                      Name{" "}
                    </Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      name={"first_name"}
                      value={values["first_name"]}
                      onChange={handleInputValue}
                      error={errors["first_name"]}
                      {...(errors["first_name"] && {
                        error: true,
                        helperText: errors["first_name"]
                      })}
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: { xs: "0.8vw", lg: "0.4vw" },
                        border: "1vh #fff",
                        width: { xs: "80%", lg: "95%" },
                        minWidth: "11vw"
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        display: "flex", justifySelf: "flex-start",
                        marginBottom: "2vh"
                      }}
                    >
                      {" "}
                      E-mail{" "}
                    </Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={values["email"]}
                      onChange={handleInputValue}
                      name={"email"}
                      error={errors["email"]}
                      {...(errors["email"] && {
                        error: true,
                        helperText: errors["email"]
                      })}
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: { xs: "0.8vw", lg: "0.4vw" },
                        border: "1vh #fff",
                        width: { xs: "80%", lg: "95%" },
                        minWidth: "11vw"
                      }}
                    />
                  </Grid>
                </Stack>

                <Grid item xs={12} sx={{ marginTop: "4vh" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      justifySelf: "flex-start",
                      marginBottom: "2vh"
                    }}
                  >
                    {" "}
                    Message{" "}
                  </Typography>

                  <TextField
                    id="outlined-multiline-flexible"
                    multiline
                    maxRows={10}
                    value={values["message"]}
                    name={"message"}
                    error={errors["message"]}
                    {...(errors["message"] && {
                      error: true,
                      helperText: errors["message"]
                    })}
                    onChange={handleInputValue}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: { xs: "0.8vw", lg: "0.4vw" },
                      border: "1vh #fff",
                      width: { xs: "80%", lg: "98%" },
                      marginBottom: "2%",
                    }}
                  />
                </Grid>

                <Button
                  size="large"
                  sx={{
                    backgroundColor: "#7BC24F",
                    color: "#FCFCFC",
                    textAlign: "center",
                    float: "right",
                    marginTop: "1%",
                    "&:hover": {
                      backgroundColor: "#2EAE6D"
                    }
                  }}
                  onClick={handleFormSubmit}
                >
                  Send
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              className="services"
              sx={{
                background: "#fff",
                padding: "2vw",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="#fff"
                  sx={{
                    textIndent: { xs: "0vw", lg: "1vw" },
                    whiteSpace: "nowrap",
                    display: "flex",
                    justifyContent: { xs: "center", lg: "flex-start" },
                    fontWeight: 600, background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)",
                    WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: "1.7rem",
                    marginBottom: "3vh"
                  }}
                >
                  You can find us here:
                </Typography>


              </Grid>
              <Grid container sx={{ minHeight: "50vh", border: "3px solid #7BC24F", borderRadius: "0.2vw", padding: "1vh", width: { xs: "90%", lg: "82%" } }}>
                <Maps lat={-26.4304052} lng={28.53167} heigh={"500px"} width={"100%"} />
              </Grid>

              <Grid container justifyContent="center" alignItems="center">
                <Stack direction="column" sx={{ color: "#2EAE6D" }}>
                  <Typography variant="body1" sx={{ padding: "1vw", marginTop: "3vh" }}>
                    Plot 161 Hallgate, Nigel, 1490
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "70vh",
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div style={{ marginTop: "25vh" }}>
            <RingLoader color="#36d7b7" />
          </div>
        </div>
      )}
    </>
  );
}
