// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import * as React from 'react';
import './400.css';
import NavBar from "../../layout/navbar/Navbar";
import { Grid } from '@mui/material';

export function NotFoundPage() {
  return (
    <>
      <Grid container sx={{ backgroundColor: "fff", margin: 0}}>
        <Grid container className="notFoundNav">
          <NavBar />
        </Grid>
    
        <Grid container sx={{display: "flex", justifyContent: "center", marginTop: "6vh"}}>
          <Typography className='notf' variant="h1"  sx={{fontSize: "6rem", margin: "auto"}}>
            404
          </Typography>
        </Grid>

        <Typography variant="h3" sx={{margin: "auto"}}>This page cannot be found.</Typography>
      </Grid>

    </>
  );
}