// components/Home.js
import { Heading } from "@aws-amplify/ui-react";
import { Link, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { Store } from "react-notifications-component";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import NavBar from "../../layout/navbar/Navbar";
import { Typography } from "@mui/material";
import { Button, TextField } from "@mui/material";
import "./modernHomes.css";
import modernHouse1 from "../../assets/woodstockie/home/PHOTO-2021-11-13-13-06-18.jpg";
import modernHouse2 from "../../assets/woodstockie/home/PHOTO-2021-11-13-13-06-23 (1).jpg";
import modernHouse3 from "../../assets/woodstockie/home/PHOTO-2021-11-13-13-06-23 (2).jpg";
import modernHouse4 from "../../assets/woodstockie/home/PHOTO-2021-11-13-13-06-23.jpg";
import modernHouse5 from "../../assets/woodstockie/home/PHOTO-2021-11-13-13-06-25.jpg";
import modernHouse6 from "../../assets/woodstockie/home/PHOTO-2022-03-10-19-17-06 (1).jpg";
import modernHouse7 from "../../assets/woodstockie/home/PHOTO-2022-03-10-19-17-06 (2).jpg";
import modernHouse8 from "../../assets/woodstockie/home/PHOTO-2022-03-10-19-17-06 (3).jpg";
import modernHouse9 from "../../assets/woodstockie/home/PHOTO-2022-03-10-19-17-06.jpg";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import mainHomeImage from "../../assets/woodstockie/home/PHOTO-2021-11-13-13-06-25.jpg";
import Stack from "@mui/material/Stack";


const mhImages = [
  modernHouse1,
  modernHouse2,
  modernHouse3,
  modernHouse4,
  modernHouse5,
  modernHouse6,
  modernHouse7,
  modernHouse8,
  modernHouse9,
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#183b25" : "#183b25",
  // padding: theme.spacing(31),
  textAlign: "center",
  boxShadow: "none",
}));



export function ModernHouse() {


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Grid container sx={{ backgroundColor: "#B5BEC366" }}>

        <Grid className="controlRoomContainer" sx={{ margin: "2vh 3vw", backgroundColor: "#024512" }}>
          <Grid item xs={12} sx={{ margin: "2vh 0 6vh 0" }}>
            <NavBar />
          </Grid>


          <Grid container sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <Grid item xs={12} md={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000", marginTop: "0vh" }}>
              <Stack direction={"column"}>
                <Typography
                  variant="h1"
                  sx={{ fontSize: "2.5em", marginBottom: "3vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #7BC24F, #FDF000)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}
                >
                  {" "} Modern Homes {" "}
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  Redefine contemporary living with modern homes crafted from container conversions. Experience the perfect blend of style and functionality as we transform shipping containers into unique living spaces.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" } }}>
                  <ul style={{ textAlign: "left", color: "#fff" }}>
                    <li>
                      <b>Inventive Design:</b> Our container homes feature cutting-edge architecture that reflects modern aesthetics and design trends.
                    </li>
                    <li>
                      <b>Sustainability:</b> Embrace eco-friendly living with repurposed containers that contribute to a greener future.
                    </li>
                    <li>
                      <b>Cost-Effective Luxury:</b> Enjoy upscale living without the premium price tag, thanks to our affordable container homes.
                    </li>
                    <li>
                      <b>Customizable Spaces:</b> Tailor your container home to match your lifestyle, from layout to interior finishes.
                    </li>
                    <li>
                      <b>Efficient Living:</b> Container homes are designed for energy efficiency, providing a comfortable and sustainable environment.
                    </li>
                    <li>
                      <b>Endless Possibilities:</b> Create your dream home, whether it's a compact urban dwelling or a spacious countryside retreat.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left", color: "#fff", margin: { xs: "auto", lg: 0 }, width: { xs: "80%", md: "100%" }, paddingBottom: "3vh" }}>
                  Elevate your living experience with container homes that merge innovation and comfort seamlessly. Discover a new way to live with modern homes by <b>Woodstock Projects.</b>
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={mainHomeImage} alt="" style={{ width: "90%", borderRadius: "0.2vw", height: "40vh", objectFit: "cover" }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid className="image-container" xs={12}>
          <Typography variant="h5" mb={4} sx={{
            fontFamily: "Poppins", fontVariant: "small-caps", marginTop: "6vh", color: "#fff", background: "-webkit-linear-gradient(90deg,  #2EAE6D, #7BC24F)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600, fontSize: "1.7rem"
          }}>
            Gallery
          </Typography>

          <Grid className="image-container" xs={12} sx={{ marginTop: "2%", marginBottom: "6vh" }}>
            {/* ... (previous code) */}
            {/* Loop through the images array and group them in rows of three */}
            {Array.from({ length: Math.ceil(mhImages.length / 3) }).map((_, rowIndex) => (
              <Grid key={rowIndex} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%", height: "100%" }}>
                  {/* Generate image components within the loop */}
                  {mhImages.slice(rowIndex * 3, rowIndex * 3 + 3).map((imagePath, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ margin: "0.5vw" }}>
                      <img className="imageCon" src={imagePath} />
                    </Grid>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>


      </Grid>
    </>
  );
}
